import React, {useCallback, useEffect, useRef} from 'react';
import Drawer from "@material-ui/core/Drawer";
import Svg from "@/Pages/All/Svg";
import withStore from "@/hocs/withStore";
import LinkLoad from "@/Layouts/LinkLoad";

const CatalogMobileSubMenu = ({...props}) => {
    const {stores = {}, catalogMenu = []} = props;
    const {subMenuStore = {}} = stores;

    useEffect(() => {
        if (categoryListContainerRef.current !== null) {
            categoryListContainerRef.current.scrollIntoView({behavior: 'smooth'})
        }
    });

    const categoryListContainerRef = useRef(null);

    let categories = [], sections = [];
    catalogMenu.map((el, i) => {
        categories.push(
            <div key={el.id}
                 className={`catalogMobileSubMenu__categoryList js-catalogMenuList${i === 0 ? ' active' : ''}`}
                 onClick={(e) => subMenuStore.changeCategoryMobileMenuCatalog(e, el)}
            >
                <Svg id={el.svg} className={`svg__catalogMobileSubMenu`} />
                {el.name}
            </div>
        );

        sections.push(
            <div key={el.id}
                 id={`catalog_mobile_sub_menu_${el.id}`}
                 className={`catalogMobileSubMenu__listContainer${i === 0 ? '' : ' hidden'}`}>
                <div ref={categoryListContainerRef}></div>
                <LinkLoad key={el.id}
                          href={el.url}
                          className={`catalogMobileSubMenu__list catalogMobileSubMenu__list_one`}
                          onClick={() => subMenuStore.closeMobileSubMenu()}
                >
                    {el.name_all}
                </LinkLoad>
                {el.sections.map((items) => {
                    return <div key={items.id}>
                        <div className={`catalogMobileSubMenu__title`}>
                            {items.title}
                        </div>
                        {items.items.map((el) => {
                            return <LinkLoad key={el.id}
                                             href={`${el.url}`}
                                             className={`catalogMobileSubMenu__list`}
                                             onClick={() => subMenuStore.closeMobileSubMenu()}
                            >
                                {el.name}
                            </LinkLoad>
                        })}
                    </div>
                })}
            </div>
        );
    });

    const Menu = useCallback(() => {
        return <div className={`catalogMobileSubMenu`}>
            <div className={`catalogMobileSubMenu__close`}
                 onClick={(e) => {
                     subMenuStore.closeMobileSubMenu();
                     // e.preventDefault();
                     // e.stopPropagation();
                 }}>
                <Svg id={`dagger-close`} className={`svg__daggerCloseMenu`} />
            </div>
            {/*<div className={`catalogMobileSubMenu__line`}></div>*/}
            <div className={`catalogMobileSubMenu__body`}>
                <div className={`catalogMobileSubMenu__categoryListContainer`}>
                    {categories}
                </div>
                {sections}
            </div>
        </div>
    }, [catalogMenu]);

    return (
        <Drawer id={`drawer`}
                className={`catalogMobileSubMenu`}
                anchor={`left`}
                open={subMenuStore.catalogMobileSubMenuFlag}
                ModalProps={{
                    keepMounted: true,
                }}
        >
            <Menu />
        </Drawer>
    )
};

export default withStore(CatalogMobileSubMenu);
